import * as R from 'ramda'
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'

// Redux
import { connect } from 'react-redux'
import dataReducer from '../../reducers/data/'

// Constants
import { mono3, mono5 } from '../../constants/colors'

// Helpers
import { getPreviousTournaments } from '../../helpers/date'

// Common
import Flex from '../common/flex'
import Space from '../common/space'

// Components
import Layout from '../layout/layout'
import Tournament from './tournament'

const SaisonEnd = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-radius: 4px;
  background-color: ${mono5};
  color: #fff;
  border: 1px solid ${mono3};
  line-height: 30px;
`

class TournamentsContainer extends Component {
  state = {
    activeIndex: 0,
  }

  getFilteredTournaments () {
    switch (this.state.activeIndex) {
      case 1:
        return getPreviousTournaments(this.props.tournaments)
      default:
        return this.props.tournaments
    }
  }

  render () {
    return (
      <Layout count={this.props.teamCount} tournament={this.props.nextTournament}>
        <Flex column>
          {!this.props.nextTournament.id && (
            <Space bottom={30}>
              <SaisonEnd>
                <div>Diese Saison finden keine weiteren Turniere mehr statt.</div>
                <div>Im Herbst werden die Daten für die nächste Saison zur Verfügung stehen.</div>
                <div>...bis dahin, einen schönen Sommer ;-)</div>
              </SaisonEnd>
            </Space>
          )}

          {R.map(
            tournament => (
              <Space bottom={30} key={tournament.id}>
                <Tournament
                  compoundContent={this.props.getCompoundContent(tournament.compound)}
                  data={tournament}
                  nid={this.props.nextTournament.id}
                  navigateToSubscribe={() => this.props.history.push('/anmelden')}
                />
              </Space>
            ),
            this.getFilteredTournaments()
          )}
        </Flex>
      </Layout>
    )
  }
}

export default withRouter(
  connect(state => {
    const nextTournament = dataReducer.selectors.getNextTournament(state.data)

    return {
      getCompoundContent: dataReducer.selectors.getCompoundContent(state.data),
      tournaments: dataReducer.selectors.getTournaments(state.data),
      nextTournament,
      teamCount: dataReducer.selectors.getCount(nextTournament.id, state.data),
    }
  })(TournamentsContainer)
)
