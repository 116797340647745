import * as R from 'ramda'
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

// Constants
import { base, mono1, mono2, mono3, mono4 } from '../../constants/colors'
import { HideSmaller } from '../../constants/layout'

// Helpers
import { toLocalDateString } from '../../helpers/date'

// Common
import Space from '../common/space'
import Flex from '../common/flex'
import { Card } from '../common/card'
import Icon from '../common/icon'
import Button from '../common/button'

// Components
import TournamentHeader from '../shared/tournament-header'

const Bold = styled.div`
  font-weight: 400;
`

const Details = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;

  padding-top: 20px;
  padding-bottom: 20px;

  border-top: 1px solid ${mono3};
  border-bottom: 1px solid ${mono3};
`

const Detail = styled(Flex)`
  &:first-child {
    margin-bottom: 20px;
  }
`

const Mail = styled.a`
  color: ${base};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`

const Bottom = styled(Flex)`
  @media screen and (max-width: 500px) {
    flex-direction: column;
  }
`

const Tag = styled.div`
  background-color: transparent;
  border-radius: 4px;
  border: 1px solid ${mono3};
  color: ${mono4};
  font-size: 0.8em;
  font-weight: 400;
  margin-right: 10px;
  padding: 8px;
  text-transform: uppercase;
`

const StyledButton = styled(Button)`
  @media screen and (max-width: 500px) {
    margin-top: 20px;
  }
`

function Tournament (props) {
  return (
    <Card {...(props.data._previous && { background: mono1 })} borderColor={props.data.color}>
      <TournamentHeader
        closing={toLocalDateString(props.data.closing)}
        compoundContent={props.compoundContent}
        date={toLocalDateString(props.data.date)}
        fields={props.data.fields}
        id={props.data.tournamentid}
        operator={props.data.operator}
        venue={props.data.venue}
      />

      <Details>
        <Detail align="center">
          <HideSmaller size={380}>
            <Space right={15}>
              <Icon name="pin" />
            </Space>
          </HideSmaller>
          <div>
            <Bold>{props.data.venue}</Bold>
            <div>{props.data.address}, {props.data.zip} {props.data.location}</div>
          </div>
        </Detail>

        <Detail align="center">
          <HideSmaller size={380}>
            <Space right={15}>
              <Icon name="person" />
            </Space>
          </HideSmaller>
          <div>
            <Bold>{props.data.holder}</Bold>
            <div>{props.data.phone}</div>
            <Mail href={`mailto:${props.data.email}`}>{props.data.email}</Mail>
          </div>
        </Detail>
      </Details>

      <Bottom justify="space-between">
        <Flex align="center">
          {R.map(competition => (
            <Tag
              key={competition}
              color={mono2}>
              {competition}
            </Tag>
          ), R.split(',', props.data.competition))}
        </Flex>

        {props.nid === props.data.id &&
          <StyledButton small onClick={props.navigateToSubscribe}>
            Jetzt Anmelden
          </StyledButton>
        }
      </Bottom>
    </Card>
  )
}

Tournament.propTypes = {
  compoundContent: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  navigateToSubscribe: PropTypes.func.isRequired,
  nid: PropTypes.string,
}

export default Tournament
