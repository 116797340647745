import React from 'react'

// Components
import Space from '../common/space'
import Flex from '../common/flex'
import { Card, CardImage } from '../common/card'
import Stores from '../common/stores'

function StoreCard() {
  return (
    <Card>
      <CardImage src="./assets/header.png" cover spacing="spacing" />
      <Flex column align="center">
        <div>Mit der TA App alle Turniere im Überblick.</div>
        <div>Kostenlos für iOS und Android verfügbar!</div>
        <Space bottom={24} />
        <Stores />
      </Flex>
    </Card>
  )
}

export default StoreCard
