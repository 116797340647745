import React from 'react'
import { withRouter } from 'react-router-dom'

// Redux
import { connect } from 'react-redux'
import dataReducer from '../../reducers/data/'
import userReducer from '../../reducers/user/'

// Common
import Flex from '../common/flex'
import Button from '../common/button'
import Space from '../common/space'

// Components
import Layout from '../layout/layout'
import ImprintContent from './imprint-content'

function ImprintPage(props) {
  return (
    <Layout count={props.teamCount} tournament={props.nextTournament}>
      <Flex align="flex-start" wide column>
        {!props.isVerified && (
          <React.Fragment>
            <Button small streched onClick={() => props.history.push('/')}>
              Jetzt Verifizieren
            </Button>
            <Space bottom={30} />
          </React.Fragment>
        )}
        <ImprintContent />
        {!props.isVerified && (
          <React.Fragment>
            <Button small streched onClick={() => props.history.push('/')}>
              Jetzt Verifizieren
            </Button>
            <Space bottom={30} />
          </React.Fragment>
        )}
      </Flex>
    </Layout>
  )
}

export default withRouter(
  connect(state => {
    try {
      const nextTournament = dataReducer.selectors.getNextTournament(state.data)

      return {
        tournaments: dataReducer.selectors.getTournaments(state.data),
        nextTournament,
        teamCount: dataReducer.selectors.getCount(nextTournament.id, state.data),
        isVerified: userReducer.selectors.isVerified(),
      }
    } catch (e) {
      return {
        tournaments: [],
        nextTournament: {},
        teamCount: null,
        isVerified: false
      }
    }
  })(ImprintPage)
)
