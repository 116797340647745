import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'

// Constants
import { mono0, mono3, mono5 } from '../../constants/colors'
import { content } from '../../constants/layout'

// Components
import Flex from '../common/flex'
import Space from '../common/space'
import Clubs from './clubs'

const ClubsWrapper = styled.div`
  background-color: #fff;
  border-top: 1px solid ${mono3};
`

const ClubsContent = styled.div`
  ${content};
  padding-top: 20px;
  padding-bottom: 20px;
`

const FooterWrapper = styled.div`
  background-color: ${mono5};
  color: ${mono0};
  text-align: center;

  @media screen and (max-width: 380px) {
    font-size: 11px;
  }
`

const FooterInner = styled(Flex)`
  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
  }
`

const FooterContent = styled(Flex)`
  ${content};
  padding-top: 40px;
  padding-bottom: 40px;
`

const Link = styled.a`
  color: ${mono0};
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`

const Seperator = styled.div`
  margin-left: 10px;
  margin-right: 10px;
`

const Copyright = styled(Flex)`
  align-items: flex-end;
  @media screen and (max-width: 768px) {
    margin-top: 30px;
  }
`

function Footer(props) {
  return (
    <div>
      <ClubsWrapper>
        <ClubsContent>
          <Clubs clubs={props.clubs} />
        </ClubsContent>
      </ClubsWrapper>

      <FooterWrapper>
        <FooterContent align="center" column>
          <FooterInner justify="space-between" wide>
            <div>
              <Flex>
                <Link href="mailto:ta@krenmayr.com">Kontakt</Link>
                <Seperator>|</Seperator>
                <Link onClick={() => props.history.push('impressum')}>Impressum</Link>
                <Seperator>|</Seperator>
                <Link onClick={() => props.history.push('datenschutz')}>Datenschutz</Link>
              </Flex>
              <Space top="10">
                <Flex justify="center">
                  <Link onClick={props.onRemoveVerification}>Verifizierung entfernen</Link>
                  {props.isAdmin && (
                    <React.Fragment>
                      <Seperator>|</Seperator>
                      <Link onClick={() => props.history.push('manage')}>Verwaltung</Link>
                    </React.Fragment>
                  )}
                </Flex>
              </Space>
            </div>

            <Copyright>
              &copy; 2013-{new Date().getFullYear()}&nbsp; <Link href="https://ask-nettingsdorf-badminton.at/" target="_blank">ASK Nettingsdorf Badminton/Racketlon</Link>
            </Copyright>
          </FooterInner>
        </FooterContent>
      </FooterWrapper>
    </div>
  )
}

Footer.propTypes = {
  isAdmin: PropTypes.bool,
  clubs: PropTypes.array.isRequired,
  onRemoveVerification: PropTypes.func.isRequired,
}

export default withRouter(Footer)
