import axios from 'axios';
import * as firebase from 'firebase';
import * as R from 'ramda';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

// Redux
import { connect } from 'react-redux';
import dataReducer from '../../reducers/data/';

import { toLocalDateString } from '../../helpers/date';

// Common
import Button from '../common/button';
import { Card } from '../common/card';
import Flex from '../common/flex';
import Input from '../common/input';
import Space from '../common/space';

import Layout from '../layout/layout';

const Wrapper = styled.div`
  width: 100%;
`;

const Headline = styled.div`
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: 400;
`;

const Hr = styled.div`
  height: 1px;
  width: 100%;
  background-color: #ececec;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const mapIndexed = R.addIndex(R.map);

class ManageContainer extends Component {
  constructor() {
    super();

    this.state = {
      form: {
        username: '',
        password: '',
      },
      formNotification: {
        title: '',
        message: '',
        receiver: '',
        hide: this.getDate(),
      },
      loading: false,
    };

    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.isSubmitDisabled = this.isSubmitDisabled.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.handleExport = this.handleExport.bind(this);
    this.handleImport = this.handleImport.bind(this);
  }

  handleChangeInput(_key, _value) {
    this.setState({
      form: Object.assign({}, this.state.form, { [_key]: _value }),
    });
  }

  isSubmitDisabled() {
    const validFormValues = R.filter(item => item, R.values(this.state.form));
    return R.not(
      R.equals(R.length(validFormValues), R.length(R.keys(this.state.form)))
    );
  }

  handleSubmit() {
    firebase
      .auth()
      .signInWithEmailAndPassword(
        this.state.form.username,
        this.state.form.password
      )
      .catch(error => {
        alert(error.message);
      });
  }

  handleLogout() {
    this.props.history.push('/logout');
  }

  handleExport() {
    this.setState({ loading: true });

    let cnt = 0;
    mapIndexed((competition, index) => {
      if (competition === 'ps') {
        return;
      }
      const output = [];
      R.map(team => {
        output.push(this.structureOutputData(team.p0));
        if (team.p1) {
          output.push(this.structureOutputData(team.p1));
        }
        return true;
      }, R.values(this.props.teams[competition]));
      // set timeout to download all files
      // since browser processes to fast and
      // replaces download link before actual
      // download starts
      setTimeout(() => {
        cnt += 1;
        this.generateAndDownloadCSV(output, competition);
        if (cnt === R.length(R.keys(this.props.teams))) {
          this.setState({ loading: false });
          alert('export abgeschlossen');
        }
      }, index * 1000);
      return true;
    }, R.keys(this.props.teams));
  }

  structureOutputData(_player) {
    return `${_player.id};${_player.lastname};${_player.firstname};;;;;;;;${_player.nationality};;;;;${_player.sex};${_player.birthdate};;;${_player.club};0;0;0\n`;
  }

  generateAndDownloadCSV(_data, _competition) {
    // add headline
    _data.unshift(
      'SpielerID;Name;Vorname;Zweitname:;Address;Adresse2;Adresse3;Postalcode;Stadt;Bundesland;Land;Telefon privat;Telefon gesch.;Handy;Email;Geschlecht;Geb;Einzelstärke;Doppelstärke;Verein;Rating1;Rating2;Rating3\n'
    );
    // prepare document header
    const csv = `data:text/csv;charset=utf-8,\uFEFF${encodeURI(
      _data.join('')
    )}`;
    // build download link
    const link = document.createElement('a');
    link.setAttribute('href', csv);
    link.setAttribute('charset', 'utf-8');
    link.setAttribute('download', `export_${_competition}.csv`);
    document.body.appendChild(link);
    // start download
    link.click();
  }

  handleImport() {
    this.setState({ loading: true });

    const spreadsheets = {
      verification:
        'https://docs.google.com/spreadsheets/d/1-Z6E24vgTDSbWbsDFJu8B7U3aWyDuozjKQzF4xH2JO4/gviz/tq?tqx=out:json',
      players:
        'https://docs.google.com/spreadsheets/d/1-Z6E24vgTDSbWbsDFJu8B7U3aWyDuozjKQzF4xH2JO4/gviz/tq?tqx=out:json',
      tournaments:
        'https://docs.google.com/spreadsheets/d/1Y91vO79h3o0f-aels-nSOMzSJ1VNcsl9MPmBoWPKXTM/gviz/tq?tqx=out:json',
      content:
        'https://docs.google.com/spreadsheets/d/1k8zkIvHY4swTm0wYeA_pXKV2-VtcBeH4HvGQTnuboOI/gviz/tq?tqx=out:json',
      valid:
        'https://docs.google.com/spreadsheets/d/1lw2p2xPmsZZwERhazjebSEYjX0i8VL7hqftP6J0C574/gviz/tq?tqx=out:json',
    };

    let cnt = 0;
    R.map(sheetKey => {
      axios.get(spreadsheets[sheetKey]).then(res => {
        const json = JSON.parse(res.data.substr(47).slice(0, -2));

        console.log(json);
        let feed = this.parseImportFeed(sheetKey, json.table);

        if (sheetKey === 'verification') {
          const _feed = {};
          feed.map(entry => {
            const lastname = entry.lastname.toLowerCase();
            _feed[entry.id] = {
              [lastname]: {
                firstname: entry.firstname,
                lastname: entry.lastname,
                id: entry.id,
              },
            };
            return true;
          });
          feed = _feed;
        }

        if (feed) {
          firebase
            .database()
            .ref(sheetKey)
            .set(feed)
            .then(() => {
              cnt += 1;
              if (cnt === R.length(R.keys(spreadsheets))) {
                this.setState({ loading: false });
                alert('import abgeschlossen');
              }
            })
            .catch(error => {
              alert(error);
            });
        }
      });
      return true;
    }, R.keys(spreadsheets));
  }

  parseImportFeed(_collection, _table) {
    const data = [];

    R.map(row => {
      const obj = {};

      let i = 0;
      R.map(c => {
        let key = _table.cols[i].label;
        if (key.indexOf('_') === 0) {
          key = key.replace('_', '');
        }

        if (key && c) {
          let value;
          if (c.f) {
            value = c.f;
          } else if (c.v) {
            value = c.v;
          } else {
            value = '';
          }
          obj[key] = value;

          i++;
        }
        return true;
      }, row.c);

      data.push(obj);
      return true;
    }, _table.rows);
    console.log('data');
    console.log(data);

    return data;
  }

  handleChangeNotification = (_key, _value) => {
    this.setState({
      formNotification: Object.assign({}, this.state.formNotification, {
        [_key]: _value,
      }),
    });
  };

  isNotificationSubmitDisabled = () => {
    const fields = R.omit(['receiver'], this.state.formNotification);
    const validFormValues = R.filter(item => item, R.values(fields));
    return R.not(R.equals(R.length(validFormValues), R.length(R.keys(fields))));
  };

  handleNoficitationSubmit = () => {
    if (this.state.formNotification.receiver.length) {
      window.alert(
        `Die Nachricht wird nur an den Empfänger mit der Expo ID ${this.state.formNotification.receiver} gesandt`
      );
      this.processPushFetch(this.state.formNotification.receiver);
      return;
    }

    this.props.dispatch(
      dataReducer.actions.addNotification({
        createdAt: String(new Date()),
        title: this.state.formNotification.title,
        message: this.state.formNotification.message,
        hide: this.state.formNotification.hide,
      })
    );

    if (
      window.confirm(
        `Nachricht wirklich an alle Empfänger verschicken?\n${this.state.formNotification.title}\n${this.state.formNotification.message}`
      )
    ) {
      R.map(receiver => {
        this.processPushFetch(receiver);
        return null;
      }, this.props.pushReceivers);
    }

    this.setState({
      formNotification: {
        title: '',
        message: '',
        receiver: '',
        hide: this.getDate(),
      },
    });
  };

  processPushFetch(receiver) {
    fetch('https://exp.host/--/api/v2/push/send', {
      mode: 'no-cors',
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'accept-encoding': 'gzip, deflate',
      },
      body: JSON.stringify({
        to: receiver,
        title: this.state.formNotification.title,
        body: this.state.formNotification.message,
        sound: 'default',
        priority: 'high',
      }),
    }).catch(err => console.log(err));
  }

  getDate() {
    const d = new Date();
    const _day = d.getDate();
    const day = _day < 10 ? `0${_day}` : _day;
    const _month = d.getMonth() + 1;
    const month = _month < 10 ? `0${_month}` : _month;
    return `${day}.${month}.${d.getFullYear()}`;
  }

  render() {
    return (
      <Layout
        count={this.props.teamCount}
        tournament={this.props.nextTournament}
      >
        <Wrapper>
          {this.props.auth && (
            <div>
              <Space bottom={32}>
                <Card>
                  <Flex justify="space-between">
                    <div>
                      <Headline>Datenexport</Headline>
                      <Button
                        disabled={this.state.loading}
                        onClick={this.handleExport}
                      >
                        Exportieren
                      </Button>
                    </div>

                    <div>
                      <Headline>Datenimport</Headline>
                      <Button
                        disabled={this.state.loading}
                        onClick={this.handleImport}
                      >
                        Importieren
                      </Button>
                    </div>
                    <div>
                      <Headline>Session</Headline>
                      <Button
                        disabled={this.state.loading}
                        onClick={this.handleLogout}
                      >
                        Logout
                      </Button>
                    </div>
                  </Flex>
                </Card>
              </Space>

              <Card>
                <Headline>Notifications</Headline>

                <Space bottom={16}>
                  <div>
                    Wird eine Expo Id eingetragen, wird die Nachricht nur an
                    diese eine Id versandt. Soll die Nachricht an alle Empfänger
                    gerichtet werden, dieses Feld leer lassen.
                  </div>
                  <Input
                    placeholder="Expo Id"
                    name="receiver"
                    value={this.state.formNotification.receiver}
                    onChange={this.handleChangeNotification}
                  />
                </Space>

                <Space bottom={16}>
                  <Input
                    placeholder="Titel"
                    name="title"
                    value={this.state.formNotification.title}
                    onChange={this.handleChangeNotification}
                  />
                </Space>

                <Space bottom={16}>
                  <Input
                    name="message"
                    placeholder="Nachricht"
                    value={this.state.formNotification.message}
                    onChange={this.handleChangeNotification}
                  />
                </Space>

                <Space bottom={16}>
                  <div>
                    Nachricht wird an diesem Tag 00:00 Uhr{' '}
                    <strong>nicht</strong> mehr auf der Startseite der App
                    angezeigt. Sichtbar im Benachrichtigungsarchiv.
                  </div>
                  <Input
                    name="hide"
                    placeholder="03.02.2018"
                    value={this.state.formNotification.hide}
                    onChange={this.handleChangeNotification}
                  />
                </Space>

                <Button
                  disabled={this.isNotificationSubmitDisabled()}
                  onClick={this.handleNoficitationSubmit}
                >
                  Senden
                </Button>

                <Space top={32}>
                  {R.map(
                    notification => (
                      <Space bottom={16} key={notification.createdAt}>
                        <Card key={notification.createdAt}>
                          <div>{toLocalDateString(notification.createdAt)}</div>
                          <strong>{notification.title}</strong>
                          <div>{notification.message}</div>
                          <Hr />
                          <div>Ausblenden am: {notification.hide}</div>
                        </Card>
                      </Space>
                    ),
                    R.reverse(R.values(this.props.notifications))
                  )}
                </Space>
              </Card>
              <Space bottom={30} />
            </div>
          )}

          {!this.props.auth && (
            <Card>
              <Space bottom={20}>
                <Input
                  name="username"
                  placeholder="Benutzername"
                  value={this.state.form.username}
                  onChange={this.handleChangeInput}
                />
              </Space>

              <Space bottom={20}>
                <Input
                  name="password"
                  placeholder="Passwort"
                  type="password"
                  value={this.state.form.password}
                  onChange={this.handleChangeInput}
                />
              </Space>

              <Button
                disabled={this.isSubmitDisabled()}
                onClick={this.handleSubmit}
              >
                Login
              </Button>
            </Card>
          )}
        </Wrapper>
        <Space bottom={30} />
      </Layout>
    );
  }
}

export default withRouter(
  connect(state => {
    const nextTournament = dataReducer.selectors.getNextTournament(state.data);

    return {
      auth: state.auth,
      nextTournament,
      teamCount: dataReducer.selectors.getCount(nextTournament.id, state.data),
      teams: dataReducer.selectors.getTeams(nextTournament.id, state.data),
      notifications: dataReducer.selectors.getNotifications(state.data),
      pushReceivers: dataReducer.selectors.getPushReceivers(state.data),
    };
  })(ManageContainer)
);
