import * as types from './types'

export function showSuccess (headline, content) {
  return {
    type: types.SHOW,
    payload: {
      type: 'success',
      headline,
      content,
    },
  }
}

export function showError (headline, content) {
  return {
    type: types.SHOW,
    payload: {
      type: 'error',
      headline,
      content,
    },
  }
}

export function showInfo (headline, content) {
  return {
    type: types.SHOW,
    payload: {
      type: 'info',
      headline,
      content,
    },
  }
}

export function showContent (component) {
  return {
    type: types.SHOW,
    payload: {
      type: 'content',
      component,
    },
  }
}

export function hide () {
  return {
    type: types.HIDE,
    payload: false,
  }
}
