import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { base, mono1, mono2, mono3, highlight } from '../../constants/colors'

// Components
import Icon from './icon'

const Container = styled.div`
  position: relative;
  display: flex;
`

const Input = styled.input`
  width: 100%;
  height: 50px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 14px;
  font-weight: 300;
  color: ${base};
  background-color: ${props => props.disabled ? mono1 : '#fff'};
  border: 1px solid ${mono3};
  border-radius: 4px;
  appearance: none;
  -webkit-appearance: none;
  -webkit-opacity: 1;

  &::placeholder {
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    line-height: 1.5;
    font-size: 14px;
    color: ${mono2};
  }
`

const Cancel = styled.div`
  position: absolute;
  height: 100%;
  right: 10px;
  cursor: pointer;
  top: 0;
  display: flex;
  align-items: center;
`

function InputField (props) {
  return (
    <Container>
      <Input
        type={props.type}
        name={props.name}
        placeholder={props.placeholder}
        value={props.value}
        disabled={props.disabled}
        onChange={e => props.onChange(props.name, e.target.value)}
      />

      {props.disabled &&
        <Cancel onClick={props.onClear}>
          <Icon name="cancel" color={highlight} size={14} />
        </Cancel>
      }
    </Container>
  )
}

InputField.defaultPropTypes = {
  type: 'text',
}

InputField.propTypes = {
  type: PropTypes.string,
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
}

export default InputField
