import * as types from './types'
import * as actions from './actions'

function reducer (state = null, action) {
  switch (action.type) {
    case types.SET:
      return action.payload

    default:
      return state
  }
}

export default {
  actions,
  reducer,
}
