import React, { Component } from 'react';
import styled from 'styled-components';

// Redux
import { connect } from 'react-redux';
import popupReducer from '../../reducers/popup';

// Constants
import { mono4, success, danger, highlight } from '../../constants/colors';

// Common
import Flex from '../common/flex';
import Space from '../common/space';
import Icon from '../common/icon';
import Button from '../common/button';

const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.2);

  ${props => props.scroll && 'overflow: scroll;'};
`;

const Centered = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const Popup = styled.div`
  min-width: 260px;
  max-width: 380px;
  padding: 20px;
  margin-left: 10px;
  margin-right: 10px;
  z-index: 2;
  background-color: #fff;
  box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.1);
`;

const ContentPopup = styled.div`
  padding: 20px;
  z-index: 2;
  background-color: #fff;
`;

const Headline = styled.div`
  font-size: 18px;
  font-weight: 400;
`;

const Content = styled.div`
  text-align: center;
  margin-top: 10px;
  color: ${mono4};

  @media screen and (min-width: 400px) {
    margin-left: 20px;
    margin-right: 20px;
  }
`;

class PopupContainer extends Component {
  handleClose = () => {
    this.props.dispatch(popupReducer.actions.hide());
  };

  getType() {
    switch (this.props.popup.type) {
      case 'success':
        return {
          color: success,
          icon: 'check',
          buttonText: 'Yeah!',
        };
      case 'error':
        return {
          color: danger,
          icon: 'check',
          buttonText: 'Verstanden',
        };
      default:
        return {
          color: highlight,
          icon: 'info',
          buttonText: 'Alles klar!',
        };
    }
  }

  render() {
    if (!this.props.popup) {
      return null;
    }

    if (this.props.popup.type === 'content') {
      return (
        <Overlay scroll>
          <ContentPopup>
            <div>{this.props.popup.component()}</div>
            <Flex justify="center">
              <Button
                small
                color={highlight}
                onClick={() => this.handleClose()}
              >
                Schließen
              </Button>
            </Flex>
          </ContentPopup>
        </Overlay>
      );
    }

    const type = this.getType(this.props.popup.type);

    return (
      <Overlay>
        <Centered>
          <Popup>
            <Flex justify="flex-end">
              <Icon
                name="cancel"
                size={18}
                onClick={() => this.handleClose()}
              />
            </Flex>
            <Space top={10} bottom={20}>
              <Flex justify="center">
                <Icon name={type.icon} color={type.color} size={60} />
              </Flex>
            </Space>

            <Space bottom={20}>
              <Flex column align="center">
                <Headline>{this.props.popup.headline}</Headline>
                <Content>{this.props.popup.content}</Content>
              </Flex>
            </Space>

            <Flex justify="center">
              <Button
                small
                color={type.color}
                onClick={() => this.handleClose()}
              >
                {type.buttonText}
              </Button>
            </Flex>
          </Popup>
        </Centered>
      </Overlay>
    );
  }
}

export default connect(state => ({
  popup: state.popup,
}))(PopupContainer);
