import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import { mono2, highlight } from '../../constants/colors'

const Btn = styled.button`
  background-color: ${props => props.color || highlight};
  border-radius: 4px;
  border: 0;
  color: #fff;
  cursor: pointer;
  display: block;
  font-size: 14px;
  padding: 16px 20px;
  text-align: center;

  &:disabled {
    background-color: ${mono2};
  }

  ${props => props.streched && 'width: 100%;'};
  ${props => props.small && css`
    padding-top: 10px;
    padding-bottom: 10px;
  `};
`

function Button (props) {
  return (
    <Btn
      className={props.className}
      color={props.color}
      disabled={props.disabled}
      small={props.small}
      streched={props.streched}
      onClick={props.onClick}>
      {props.children}
    </Btn>
  )
}

Button.propTypes = {
  children: PropTypes.node,
  color: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  small: PropTypes.bool,
  streched: PropTypes.bool,
  onClick: PropTypes.func,
}

export default Button
