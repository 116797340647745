import * as firebase from 'firebase';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';
import dataReducer from '../reducers/data';
import userReducer from '../reducers/user';

// Router
import Router from '../router/router';

// components
import Flex from './common/flex';
import Footer from './footer/footer';
import Topbar from './topbar/topbar';

class RootContainer extends Component {
  constructor(props) {
    super(props);

    this.removeVerification = this.removeVerification.bind(this);
  }

  componentDidMount() {
    const oldUser = localStorage.getItem('@taUser');
    if (oldUser) {
      localStorage.removeItem('@taUser');
      this.removeVerification();
    }
  }

  removeVerification() {
    firebase
      .auth()
      .signOut()
      .then(() => {
        this.props.dispatch(userReducer.actions.remove());
        setTimeout(() => {
          window.location.reload();
        }, 1);
      })
      .catch(function (error) {
        alert(
          'Die Sitzung konnte nicht beendet werden. Logout fehlgeschlagen!'
        );
      });
  }

  render() {
    if (!this.props.data) {
      return null;
    }

    return (
      <Flex column wide>
        <Topbar isSeasonEnd={!this.props.nextTournament.id} />

        <Router />

        <Footer
          isAdmin={['32023', '30242'].indexOf(this.props.user.id) >= 0}
          clubs={this.props.clubs}
          onRemoveVerification={() => {
            this.removeVerification();
          }}
        />
      </Flex>
    );
  }
}

export default withRouter(
  connect(state => ({
    data: state.data,
    user: userReducer.selectors.get(),
    nextTournament: dataReducer.selectors.getNextTournament(state.data),
    clubs: dataReducer.selectors.getClubs(state.data),
  }))(RootContainer)
);
