import React from 'react'
import styled from 'styled-components'

import Flex from '../common/flex'

const Link = styled.a`
  margin-left: 10px;
  margin-right: 10px;
  text-decoration: none;
  border: none;
`

function Stores () {
  return (
    <Flex justify="center">
      <Link href="https://itunes.apple.com/gb/app/apple-store/id1186802173" target="_blank">
        <img src="assets/stores/app_store.svg" alt="TA - App Store" />
      </Link>
      <Link href="https://play.google.com/store/apps/details?id=com.ionicframework.taapp342298&hl=de" target="_blank">
        <img src="assets/stores/play_store.svg" alt="TA - Play Store" />
      </Link>
    </Flex>
  )
}

export default Stores
