import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'

// Constants
import { mono0, mono3, mono5 } from '../../constants/colors'
import { content, HideSmaller, HideLarger } from '../../constants/layout'

// Components
import Flex from '../common/flex'
import Icon from '../common/icon'
import Space from '../common/space'
import Logo from './logo'
import Navigation from './navigation'


const Wrapper = styled.div`
  position: relative;
  background-color: ${mono5};
  height: 70px;
`

const Container = styled(Flex)`
  ${content};
  height: 100%;
`

const MobileWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: ${mono5};
`

const MobileClose = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 70px;
`

const Copyright = styled.div`
  position: absolute;
  bottom: 20px;
  width: 100%;
  text-align: center;
  font-size: 11px;
  color: ${mono3};
`

class Topbar extends Component {
  state = {
    collapsed: false,
  }

  navigateTo(path) {
    this.setState({ collapsed: false })
    this.props.history.push(path)
  }

  renderNavigation(opts = {}) {
    return (
      <Navigation
        {...opts}
        isSeasonEnd={this.props.isSeasonEnd}
        pathname={this.props.location.pathname}
        onClick={path => this.navigateTo(path)}
      />
    )
  }

  render() {
    return (
      <Wrapper>
        <Container align="center" justify="space-between">
          <Logo onClick={() => this.navigateTo('/')} />

          <HideSmaller size={768}>
            {this.renderNavigation()}
          </HideSmaller>

          <HideLarger size={769}>
            <Icon
              color={mono0}
              name="navigation"
              onClick={() => this.setState({ collapsed: !this.state.collapsed })}
            />
            {this.state.collapsed &&
              <MobileWrapper>
                <Space right={20} bottom={50}>
                  <MobileClose>
                    <Icon
                      color="#fff"
                      name="cancel"
                      onClick={() => this.setState({ collapsed: false })}
                    />
                  </MobileClose>
                </Space>

                {this.renderNavigation({ mobile: true })}

                <Copyright>
                  &copy; 2013-2018 ASK Nettingsdorf Badminton/Racketlon
                </Copyright>
              </MobileWrapper>
            }
          </HideLarger>
        </Container>
      </Wrapper>
    )
  }
}

Topbar.propTypes = {
  isSeasonEnd: PropTypes.any,
}

export default withRouter(Topbar)
