import * as firebase from 'firebase';
import * as types from './types';

export function get(ref) {
  const request = firebase.database().ref(`/${ref}`).once('value');

  return {
    type: types.GET,
    payload: request,
    meta: {
      ref,
    },
  };
}

export function addTeam(payload) {
  return dispatch => {
    firebase
      .database()
      .ref(`/teams/${payload.tid}/${payload.competition}`)
      .push(payload.selection)
      .then(data => {
        dispatch({
          type: types.ADD_TEAM,
          payload: {
            key: data.key,
            subscription: payload,
            tid: payload.tid,
          },
        });
      });
  };
}

export function addNotification(payload) {
  return dispatch => {
    firebase
      .database()
      .ref('/notifications')
      .push(payload)
      .then(data => {
        dispatch({
          type: types.ADD_NOTIFICATION,
          payload: {
            key: data.key,
            data: payload,
          },
        });
      });
  };
}

export function remove(payload) {
  firebase
    .database()
    .ref(`teams/${payload.tid}/${payload.competition}/${payload.key}`)
    .remove();

  return {
    type: types.REMOVE,
    payload,
  };
}
