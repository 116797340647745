import * as R from 'ramda';

import * as actions from './actions';
import * as selectors from './selectors';
import * as types from './types';

function reducer(state = null, action) {
  switch (action.type) {
    case types.GET:
      return R.assocPath([action.meta.ref], action.payload.val(), state);

    case types.ADD_TEAM:
      return R.assocPath(
        [
          'teams',
          action.payload.tid,
          action.payload.subscription.competition,
          action.payload.key,
        ],
        action.payload.subscription.selection,
        state
      );

    case types.ADD_NOTIFICATION:
      return R.assocPath(
        ['notifications', action.payload.key],
        action.payload.data,
        state
      );

    case types.REMOVE:
      return R.dissocPath(
        [
          'teams',
          action.payload.tid,
          action.payload.competition,
          action.payload.key,
        ],
        state
      );

    default:
      return state;
  }
}

export default {
  actions,
  reducer,
  selectors,
};
