import React from 'react'
import styled from 'styled-components'

// Constants
import { highlight } from '../../constants/colors'

import { Card } from '../common/card'
import Space from '../common/space'


const Headline = styled.div`
  font-size: 20px;
  margin-bottom: 20px;
`

const SubHeadline = styled.div`
  font-size: 16px;
  margin-bottom: 16px;
`

const P = styled.p`
  margin-bottom: 10px;
`

const Link = styled.a`
  color: ${highlight};
  text-decoration: none;
`

function ImprintContent() {
  return (
    <div>
      <Card>
        <Headline>Impressum</Headline>
        <p>Daniel Krenmayr</p>
        <p>Weichstetten-Süd 177</p>
        <p>4502 St. Marien</p>
        <p><Link href="mailto:ta@krenmayr.com">ta@krenmayr.com</Link></p>
      </Card>

      <Space bottom={30} />

      <Card>
        <Headline>Datenschutzerklärung</Headline>
        <Space bottom={30}>
          <P>Der Schutz Ihrer persönlichen Daten ist uns ein besonderes Anliegen. Wir verarbeiten Ihre Daten daher ausschließlich auf Grundlage der gesetzlichen Bestimmungen (DSGVO, TKG 2003).</P>
          <P>Die Datenschutzerklärung wurde vom österreichischen Badminton Verband auf rechtlicher Basis geprüft und informiert Sie über die wichtigsten Aspekte der Datenverarbeitung im Rahmen unserer Website.</P>
        </Space>

        <Space bottom={30}>
          <SubHeadline>Datenspeicherung</SubHeadline>
          <P>Wir weisen darauf hin, dass zum Zweck des einfacheren Anmeldevorganges zu OÖBV Doppel Ranglistentunieren Spieler-Meldedaten des OÖ. Badmintonverband herangezogen, gespeichert und verarbeitet werden. Diese Daten werden bis zum jeweiligen Saisonende gespeichert und umfassen: Vorname, Nachname, Geburtstag, Geschlecht, Nationalität, OÖBV Spieler Identifikation, zugeordneter Verein sowie die jeweilige Vereinskennzahl. Diese Daten sind für eine konforme Anmeldung zu OÖBV Doppel Ranglistenturnieren erforderlich. Ohne diese Daten kann eine Anmeldung nicht durchgeführt werden.</P>
          <P>Im Zuge einer Anmeldung zu einem OÖBV Doppel Ranglistenturnier erheben, speichern und ordnen wir folgende Daten der anmeldenden Person einer Anmeldung zu: Vorname, Nachname, OÖBV Spieler Identifikation, Timestamp sowie die genutzte Plattform (web oder app).</P>
          <P>Eine Datenübermittlung an den Turnierausrichter erfolgt vor jedem auszutragenden Turnier. Hier werden alle Spieler-Meldedaten die sich für das jeweilige Turnier angemeldet haben, oder durch Dritte angemeldet wurden, an den Turnierausrichter in Form einer CSV-Datei übergeben. Der Turnierausrichter hat nach Durchführung des Turniers alle Daten dauerhaft zu löschen.</P>
          <P>Die Datenverarbeitung erfolgt auf Basis der gesetzlichen Bestimmungen des § 96 Abs 3 TKG sowie des Art 6 Abs 1 lit a (Einwilligung) und/oder lit b (notwendig zur Vertragserfüllung) der DSGVO.</P>
        </Space>

        <Space bottom={30}>
          <SubHeadline>Cookies / LocalStorage</SubHeadline>
          <P>Unsere Website nutzt eine Datenspeicherung im LocalStorage. Dabei handelt es sich um kleine Datensätze, die im Zuge der Verifizierung mit Hilfe des Browsers auf Ihrem Endgerät abgelegt werden. Diese richten keinen Schaden an. Wir nutzen den LocalStorage dazu, unser Angebot nutzerfreundlich zu gestalten. Diese gespeicherten Daten bleiben auf Ihrem Endgerät gespeichert, bis Sie diese löschen. Sie ermöglichen es uns, Ihren Browser beim nächsten Besuch wiederzuerkennen.</P>
          <P>Wenn Sie dies nicht wünschen, kann eine Verifizierung nicht erfolgen.</P>
          <P>Bei der Deaktivierung des LocalStorage kann die Funktionalität unserer Website eingeschränkt sein.</P>
          <P>Nach erfolgreicher Verifizierung werden folgende Daten unter dem key @TaPlayer dauerhaft im LocalStorage gespeichert: Geburtstag, Verein, Vereinskennzahl, Vorname, Nachname, Geschlecht, Nationalität, OÖBV Spieler Identifikation sowie die entsprechende Spielerlaubnis.</P>
          <P>Wird die Cookie Information mit einem Klick auf den Button "Akzeptieren" bestätigt, wird diese Information unter dem key @taCookieAccepted im LocalStorage gespeichert.</P>
        </Space>

        <SubHeadline>Ihre Rechte</SubHeadline>
        <P>Ihnen stehen grundsätzlich die Rechte auf Auskunft, Berichtigung, Löschung, Einschränkung, Datenübertragbarkeit, Widerruf und Widerspruch zu. Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt oder Ihre datenschutzrechtlichen Ansprüche sonst in einer Weise verletzt worden sind, wenden Sie sich bitte an den Medieninhaber der Seite turnieranmeldung.at oder den österreichischen Badminton Verband.</P>
      </Card>

      <Space bottom={30} />
    </div>
  )
}

export default ImprintContent
