import styled, { css } from 'styled-components'

export const content = css`
  max-width: 1140px;
  margin: 0 auto;

  @media screen and (max-width: 1180px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`

export const HideSmaller = styled.div`
  @media screen and (max-width: ${props => props.size}px) {
    display: none;
  }
`

export const HideLarger = styled.div`
  @media screen and (min-width: ${props => props.size}px) {
    display: none;
  }
`
