import React from 'react'
import PropTypes from 'prop-types' 
import styled from 'styled-components'

import { mono0 } from '../../constants/colors'
import Flex from '../common/flex'

const Wrapper = styled.div`
  cursor: pointer;
`

const LogoImage = styled.img`
  height: 40px;
  margin-right: 10px;
`

const Title = styled.div`
  color: ${mono0};
`

function Logo (props) {
  return (
    <Wrapper>
      <Flex align="center" onClick={props.onClick}>
        <LogoImage src="assets/logo.png" />
        <Title>Turnieranmeldung</Title>
      </Flex>
    </Wrapper>
  )
}

Logo.propTypes = {
  onClick: PropTypes.func.isRequired,
}

export default Logo
