import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'

// Components
import Space from '../common/space'

import SubscriptionCard from './subscription-card'
import StoreCard from './store-card'

const Link = styled.div`
  cursor: pointer;
`

function Sidebar(props) {
  return (
    <React.Fragment>
      {
        props.tid &&
        props.count >= 0 && (
          <Space bottom={30}>
            <Link onClick={() => props.history.push('teams')}>
              <SubscriptionCard count={props.count} />
            </Link>
          </Space>
        )}

      <Space bottom={30}>
        <StoreCard />
      </Space>
    </React.Fragment>
  )
}

Sidebar.propTypes = {
  count: PropTypes.number,
  tid: PropTypes.any,
}

export default withRouter(Sidebar)
