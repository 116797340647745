import * as types from './types'
import * as actions from './actions'
import * as selectors from './selectors'

function reducer (state = {}, action) {
  switch (action.type) {
    case types.SET:
      return action.payload

    case types.REMOVE:
      return {}

    default:
      return state
  }
}

export default {
  actions,
  reducer,
  selectors,
}
