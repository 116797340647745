import PropTypes from 'prop-types'
import styled from 'styled-components'

const Flex = styled.div`
  display: ${props => (props.inline ? 'inline-flex' : 'flex')};
  ${props => props.column && 'flex-direction: column;'};
  ${props => props.wrap && 'flex-wrap: wrap;'};
  ${props => props.align && `align-items: ${props.align};`};
  ${props =>
    props.justify && `justify-content: ${props.justify};`};
  ${props => !props.inline && props.wide && 'width: 100%;'};
`
Flex.propTypes = {
  align: PropTypes.oneOf([
    'flex-start',
    'center',
    'flex-end',
    'stretch',
    'baseline',
  ]),
  column: PropTypes.bool,
  justify: PropTypes.oneOf([
    'flex-start',
    'center',
    'flex-end',
    'space-between',
    'space-around',
  ]),
  inline: PropTypes.bool,
  wide: PropTypes.bool,
  wrap: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
}

export default Flex
