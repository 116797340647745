import * as R from 'ramda';
import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { withRouter } from 'react-router-dom';
import styled, { css } from 'styled-components';

// Redux
import { connect } from 'react-redux';
import dataReducer from '../../reducers/data/';
import popupReducer from '../../reducers/popup';
import userReducer from '../../reducers/user';

// Constants
import { mono1, mono3, mono5 } from '../../constants/colors';

// Helpers
import {
  getCompetitionName,
  getPlayerCount,
  getPlayerSex,
} from '../../helpers/competitions';
import {
  getTimestampEndOfDay,
  stringToTimestamp,
  toLocalDateString,
} from '../../helpers/date';

// Components
import Button from '../common/button';
import { Card } from '../common/card';
import Flex from '../common/flex';
import Select from '../common/select';
import Space from '../common/space';
import Layout from '../layout/layout';

// Components
import TournamentHeader from '../shared/tournament-header';
import SelectPlayer from './select-player';

const Closing = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-radius: 4px;
  color: ${mono5};
  background-color: ${mono1};
  border: 1px solid ${mono3};
  line-height: 30px;

  ${props =>
    props.invent &&
    css`
      background-color: ${mono5};
      color: #fff;
    `};

  ${props =>
    props.small &&
    css`
      align-items: flex-start;
      padding: 11px;
      font-size: 12px;
      color: ${mono5};
    `};
`;

const Topic = styled.div`
  font-size: 14px;
  margin-bottom: 10px;
  font-weight: 400;
`;

const Bold = styled.span`
  font-weight: 400;
`;

const Paragraph = styled.div`
  font-size: 12px;
  text-align: justify;
`;

const InitialPlayerState = { value: '', autoComplete: null, selection: null };

class SubscribeContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      p0: InitialPlayerState,
      p1: InitialPlayerState,
      competition:
        props.nextTournament.id &&
        R.head(R.split(',', props.nextTournament.competition)),
      loading: false,
    };
  }

  // change to selected competition via select input
  // clear player selection when competition is changed
  handleChangeCompetition = _competition => {
    this.setState({
      p0: InitialPlayerState,
      p1: InitialPlayerState,
      competition: _competition,
    });
  };

  // process input value for player search
  // get auto complete search depending on player sex
  handleChangePlayerInput = (_key, _value = '') => {
    const playerSex = getPlayerSex(this.state.competition);

    this.setState({
      [_key]: {
        value: _value,
        autoComplete: this.props.autoCompletion(
          _value.toLocaleLowerCase(),
          playerSex
        ),
      },
    });
  };

  // Selection is processed when player was choosen from auto complete search
  handlePlayerSelection = (_key, _player) => {
    this.setState({
      [_key]: {
        value: `${_player.firstname} ${_player.lastname} (${_player.id})`,
        autoComplete: null,
        selection: _player,
      },
    });
  };

  // reset player selection for input field
  handleClearPlayerSelection = _key => {
    this.setState({
      [_key]: {
        value: '',
        selection: null,
      },
    });
  };

  // get structure for competition selector
  getCompetitionOptions() {
    return R.map(
      competition => ({
        key: competition,
        label: getCompetitionName(competition),
      }),
      R.split(',', this.props.nextTournament.competition)
    );
  }

  // which competition is currently selected
  // fallback and initial value is always for entry from nextTournament
  getCompetition() {
    return (
      this.state.competition ||
      R.head(R.split(',', this.props.nextTournament.competition))
    );
  }

  // determine count of players needed for selected competition
  getAvailableCompetitors() {
    const playerCount = getPlayerCount(this.getCompetition());
    return playerCount > 1 ? ['p0', 'p1'] : ['p0'];
  }

  // returns choosen player
  getPlayerSelections() {
    return {
      p0: this.state.p0.selection,
      p1: this.state.p1.selection,
    };
  }

  // determines if subscription is still possible
  subscriptionClosed() {
    const closing = getTimestampEndOfDay(
      stringToTimestamp(this.props.nextTournament.closing)
    );
    return closing <= new Date();
  }

  isSubmitDisabled = () => {
    if (this.state.loading) {
      return true;
    }

    // filter valid selections
    const selections = R.filter(
      item => item,
      R.values(this.getPlayerSelections())
    );
    // get player count depending on competition
    const playerCount = getPlayerCount(this.getCompetition());

    return R.not(R.equals(selections.length, playerCount));
  };

  handleSubmit() {
    this.setState({ loading: true });

    const subscription = this.isSubscriptionValid();

    // break is subscription has errors
    if (subscription.error) {
      this.props.dispatch(
        popupReducer.actions.showError('Upps!', subscription.error)
      );
      this.resetForm();
      return;
    }

    // get team and add meta information
    const selection = this.getPlayerSelections();
    selection.meta = {
      createdAt: String(new Date()),
      platform: 'web',
      user: {
        id: this.props.user.id,
        firstname: this.props.user.firstname,
        lastname: this.props.user.lastname,
      },
    };

    // add team
    this.props.dispatch(
      dataReducer.actions.addTeam({
        tid: this.props.nextTournament.id,
        competition: this.state.competition,
        selection,
      })
    );

    const playerCount = getPlayerCount(this.state.competition);
    const additionalMessage =
      playerCount > 1 ? `und ${this.state.p1.selection.firstname} ` : '';

    // show success message
    this.props.dispatch(
      popupReducer.actions.showSuccess(
        'Anmeldung erfolgreich',
        `Du hast ${this.state.p0.selection.firstname} ${additionalMessage}erfolgreich für ${this.props.compoundContent.article2} ${this.props.nextTournament.tournamentid}. ${this.props.compoundContent.type} in ${this.props.nextTournament.location} angemeldet.`
      )
    );

    this.resetForm();
  }

  isSubscriptionValid() {
    if (this.subscriptionClosed()) {
      return { error: 'Der Nennschluss ist bereits erreicht' };
    }

    if (
      this.state.p1.selection &&
      this.state.p0.selection.id === this.state.p1.selection.id
    ) {
      return {
        error:
          'Dein schizophres Ich ist leider kein zugelassener Spielpartner ;-)',
      };
    }

    const playerSex = getPlayerSex(this.state.competition);
    if (this.state.competition !== 'xd' && playerSex === 'wm') {
      if (this.state.p0.selection.sex === this.state.p1.selection.sex) {
        return {
          error:
            'Bitte beachte, dass für ein Mixed Doppel beide Geschlechter am Feld stehen sollten ;-)',
        };
      }
    }

    const subscribedTeams = this.props.teams[this.state.competition] || [];

    const teamIds = R.filter(
      id => id != null,
      R.map(player => player && player.id, R.values(this.getPlayerSelections()))
    );

    let complementaryCompetition;
    let complementarySubscribedTeams;

    if (
      [
        'de-a',
        'de-b',
        'dd-a',
        'dd-b',
        'he-a',
        'he-b',
        'hd-a',
        'hd-b',
        'md-a',
        'md-b',
      ].indexOf(this.state.competition) >= 0
    ) {
      switch (this.state.competition) {
        case 'de-a':
          complementaryCompetition = 'de-b';
          break;
        case 'de-b':
          complementaryCompetition = 'de-a';
          break;
        case 'dd-a':
          complementaryCompetition = 'dd-b';
          break;
        case 'dd-b':
          complementaryCompetition = 'dd-a';
          break;
        case 'he-a':
          complementaryCompetition = 'he-b';
          break;
        case 'he-b':
          complementaryCompetition = 'he-a';
          break;
        case 'hd-a':
          complementaryCompetition = 'hd-b';
          break;
        case 'hd-b':
          complementaryCompetition = 'hd-a';
          break;
        case 'md-a':
          complementaryCompetition = 'md-b';
          break;
        case 'md-b':
          complementaryCompetition = 'md-a';
          break;
        default:
          complementaryCompetition = null;
      }

      complementarySubscribedTeams = this.props.teams[complementaryCompetition];
    }

    const match = R.filter(team => {
      if (R.length(teamIds) > 1) {
        return (
          R.contains(team.p0.id, teamIds) || R.contains(team.p1.id, teamIds)
        );
      }
      return R.contains(team.p0.id, teamIds);
    }, subscribedTeams);

    // check for ['de-a', 'de-b', 'dd-a', 'dd-b', 'he-a', 'he-b', 'hd-a', 'hd-b', 'md-a', 'md-b']
    if (complementarySubscribedTeams) {
      const matchComplementary = R.filter(team => {
        if (R.length(teamIds) > 1) {
          return (
            R.contains(team.p0.id, teamIds) || R.contains(team.p1.id, teamIds)
          );
        }
        return R.contains(team.p0.id, teamIds);
      }, complementarySubscribedTeams);

      if (R.keys(matchComplementary).length) {
        return {
          error: `Ein Spieler ist bereits für ${getCompetitionName(
            complementaryCompetition
          )} angemeldet. Möchtest du eine Nennung ändern, wende dich bitte an ta@krenmayr.com`,
        };
      }
    }
    // Normal Subscriptions
    if (R.keys(match).length) {
      return {
        error:
          'Ein oder mehrere Spieler sind bereits für diesen Bewerb genannt.',
      };
    }

    return {};
  }

  resetForm() {
    this.setState({
      p0: InitialPlayerState,
      p1: InitialPlayerState,
      loading: false,
    });
  }

  render() {
    if (!this.props.nextTournament.id) {
      return <Redirect to="/" />;
    }

    return (
      <Layout
        count={this.props.teamCount}
        tournament={this.props.nextTournament}
      >
        <Flex column>
          <Space bottom={30}>
            <Card>
              <TournamentHeader
                closing={toLocalDateString(this.props.nextTournament.closing)}
                compoundContent={this.props.compoundContent}
                date={toLocalDateString(this.props.nextTournament.date)}
                fields={this.props.nextTournament.fields}
                id={this.props.nextTournament.tournamentid}
                operator={this.props.nextTournament.operator}
                venue={this.props.nextTournament.venue}
              />
            </Card>
          </Space>

          {this.subscriptionClosed() && (
            <Space bottom={30}>
              <Closing invent>
                <div>
                  Der Nennschluss für dieses Turnier war am{' '}
                  {this.props.nextTournament.closing} um 23:59 Uhr.
                </div>
                <div>Eine Anmeldung ist leider nicht mehr möglich.</div>
              </Closing>
            </Space>
          )}

          {!this.subscriptionClosed() && (
            <React.Fragment>
              <Space bottom={30}>
                <Closing>
                  Der Nennschluss für dieses Turnier ist am{' '}
                  {this.props.nextTournament.closing} um 23:59 Uhr.
                </Closing>
              </Space>

              <Space bottom={30}>
                <Card>
                  <Space bottom={20}>
                    <Select
                      options={this.getCompetitionOptions()}
                      onChange={e =>
                        this.handleChangeCompetition(e.target.value)
                      }
                    />
                  </Space>

                  {R.map(
                    item => (
                      <SelectPlayer
                        autoComplete={this.state[item].autoComplete}
                        disabled={Boolean(this.state[item].selection)}
                        key={item}
                        name={item}
                        onChange={(key, value) =>
                          this.handleChangePlayerInput(key, value)
                        }
                        onClear={() => this.handleClearPlayerSelection(item)}
                        onSelect={player =>
                          this.handlePlayerSelection(item, player)
                        }
                        value={this.state[item].value}
                      />
                    ),
                    this.getAvailableCompetitors()
                  )}

                  {this.state.competition.indexOf('x') > 0 && (
                    <Space bottom={30}>
                      <Closing small>
                        Beachte, dass dir bei diesem Bewerb dein Partner für das{' '}
                        {getCompetitionName(this.state.competition).replace(
                          ' X',
                          ''
                        )}{' '}
                        vor Turnierstart zugelost wird.
                      </Closing>
                    </Space>
                  )}

                  <Button
                    streched
                    disabled={this.isSubmitDisabled()}
                    onClick={() => this.handleSubmit()}
                  >
                    Jetzt Anmelden
                  </Button>
                </Card>
              </Space>
            </React.Fragment>
          )}

          <Space bottom={30}>
            <Card>
              <Topic>AUSZUG AUS DER WETTSPIELORDNUNG</Topic>

              {this.props.compoundContent.restrictions.length > 0 && (
                <Space bottom={20}>
                  <Paragraph>
                    {this.props.compoundContent.restrictions}
                  </Paragraph>
                </Space>
              )}

              {this.props.compoundContent.compound === 'oebv-einzel' &&
                this.props.compoundContent.restrictions.length <= 0 && (
                  <React.Fragment>
                    <Space bottom={20}>
                      <Topic>§ 8 - OÖ Einzel-Ranglistenturniere</Topic>
                      <Paragraph>
                        Setzung zu OÖ Einzel-Ranglistenturniere: nach der
                        österreichischen Einzel-Rangliste. Ergebnisse zählen zur
                        ÖBV Einzel-Rangliste.
                      </Paragraph>
                    </Space>

                    <Space bottom={20}>
                      <Topic>(1) ALLGEM. KLASSE</Topic>
                      <Paragraph>
                        Spielberechtigt sind alle Spieler/Innen mit gültiger ÖBV
                        Spielerlizenz.
                      </Paragraph>
                      <Space top={10} bottom={10}>
                        <Paragraph>
                          <Bold>Herren: </Bold>Ab Platz 21 der gültigen
                          österreichischen B-Einzelrangliste. Schüler U15
                          bedürfen der Zustimmung des zuständigen Referenten
                          beim OÖBV. Spieler, die in einer Vereinsrangliste
                          eines Bundesliga Vereines (egal ob als Stamm- oder als
                          Leihspieler) unter den ersten 4 einer Mannschaft der
                          1. oder 2. Bundesliga aufscheinen, sind nicht
                          spielberechtigt.
                        </Paragraph>
                      </Space>
                      <Space top={10} bottom={10}>
                        <Paragraph>
                          <Bold>Damen: </Bold>Ab Platz 17 der österreichischen
                          A-Einzelrangliste. Schülerinnen U15 bedürfen der
                          Zustimmung des zuständigen Referenten beim OÖBV.
                          Spielerinnen, die in einer Vereinsrangliste eines
                          Bundesliga Vereines (egal ob als Stamm- oder als
                          Leihspielerin) unter den ersten 2 einer Mannschaft der
                          1. oder 2. Bundesliga aufscheinen, sind nicht
                          spielberechtigt.
                        </Paragraph>
                      </Space>
                      <Space top={10} bottom={10}>
                        <Paragraph>
                          <Bold>Allgemein: </Bold>In Ausnahmefällen kann das
                          OÖBV Ligagremium Spielerinnen und Spieler die
                          Teilnahme verwehren, wenn sie in keiner Rangliste
                          aufscheinen allerdings der Spielstärke wie oben
                          definiert entsprechen.
                        </Paragraph>
                      </Space>
                      <Space top={10}>
                        <Paragraph>
                          <Bold>Modus der Einzelbewerbe: </Bold>Es gibt bei
                          jedem Turnier für Damen und Herren jeweils einen
                          Amateur (DE-A, HE-A) sowie einen Beginner (DE-B, HE-B)
                          Bewerb. Fünf Nennungen ist die minimale Teilnehmerzahl
                          pro Bewerb, ansonsten werden die beiden Bewerbe A und
                          B in eine Gruppe zusammengefasst. Die Turnierraster
                          werden nach dem Vorbild der ÖBV Turnierraster für
                          Einzelturniere gespielt. Generell werden Sätze bis 21
                          Punkte mit Verlängerung bis maximal 30 Punkte
                          gespielt. Falls die Anzahl der Nennungen für diese
                          Turniere den Zeitrahmen von 10:00 Uhr bis maximal
                          21:00 Uhr sprengen würde, wird auf kurze Sätze bis 15
                          Punkte mit Verlängerung bis maximal 20 Punkte
                          gespielt.
                        </Paragraph>
                      </Space>
                    </Space>
                  </React.Fragment>
                )}

              {this.props.compoundContent.compound === 'oebv' &&
                this.props.compoundContent.restrictions.length <= 0 && (
                  <React.Fragment>
                    <Space bottom={20}>
                      <Topic>§ 10 - OÖ DOPPEL-RANGLISTENTURNIERE</Topic>
                      <Paragraph>
                        Es werden die Bewerbe Damen-, Herren- und Mixed-Doppel
                        für Oberösterreich ausgespielt.
                      </Paragraph>
                      <Paragraph>
                        Setzung zu OÖ Nachwuchs-Doppel-Ranglistenturniere: nach
                        der oberösterreichischen Doppel-Rangliste.
                      </Paragraph>
                    </Space>

                    <Space bottom={20}>
                      <Topic>(1) ALLGEM. KLASSE</Topic>
                      <Paragraph>
                        Spielberechtigt sind alle Spieler/Innen mit gültiger ÖBV
                        Spielerlizenz. Durch den Erwerb der OÖ-Doppel-Lizenz
                        (lt. Finanzordnung) sind auch die Vereinsspieler/Innen
                        der Spielgemeinschaft Turn- und Sportverein 1896
                        Freilassing - ASV Piding spielberechtigt.
                      </Paragraph>
                      <Space top={10} bottom={10}>
                        <Paragraph>
                          <Bold>Herren: </Bold>Ab Platz 21 der gültigen
                          österreichischen B-Einzelrangliste. Schüler U15
                          bedürfen der Zustimmung des zuständigen Referenten
                          beim OÖBV. Spieler, die in einer Vereinsrangliste
                          eines Bundesliga Vereines (egal ob als Stamm- oder als
                          Leihspieler) unter den ersten 4 einer Mannschaft der
                          1. oder 2. Bundesliga aufscheinen, sind nicht
                          spielberechtigt.
                        </Paragraph>
                      </Space>
                      <Space top={10} bottom={10}>
                        <Paragraph>
                          <Bold>Damen: </Bold>Ab Platz 17 der österreichischen
                          A-Einzelrangliste. Schülerinnen U15 bedürfen der
                          Zustimmung des zuständigen Referenten beim OÖBV.
                          Spielerinnen, die in einer Vereinsrangliste eines
                          Bundesliga Vereines (egal ob als Stamm- oder als
                          Leihspielerin) unter den ersten 2 einer Mannschaft der
                          1. oder 2. Bundesliga aufscheinen, sind nicht
                          spielberechtigt.
                        </Paragraph>
                      </Space>
                      <Space top={10} bottom={10}>
                        <Paragraph>
                          <Bold>Allgemein: </Bold>In Ausnahmefällen kann das
                          Ligagremium/der Wettkampfausschuss Spielerinnen und
                          Spieler die Teilnahme verwehren, wenn sie in keiner
                          Rangliste aufscheinen allerdings der Spielstärke wie
                          oben definiert entsprechen.
                        </Paragraph>
                      </Space>
                      <Space top={10}>
                        <Paragraph>
                          <Bold>Modus der Doppelbewerbe: </Bold>Turniere mit
                          (DD, HD) oder nur (MD): Die Turnierraster werden nach
                          dem Vorbild der ÖBV Turnierraster für Einzelturniere
                          gespielt. Generell werden Sätze bis 21 Punkte mit
                          Verlängerung bis maximal 30 Punkte gespielt. Falls die
                          Anzahl der Nennungen für diese Turniere den Zeitrahmen
                          von 10:00 Uhr bis maximal 21:00 Uhr sprengen würde,
                          wird auf kurze Sätze bis 15 Punkte mit Verlängerung
                          bis maximal 20 Punkte gespielt. Turniere mit (DD, HD,
                          MD): Die Turnierraster werden nach dem Vorbild der ÖBV
                          Turnierraster für Einzelturniere gespielt. Generell
                          werden die Bewerbe auf kurze Sätze bis 15 Punkte mit
                          Verlängerung bis maximal 20 Punkte gespielt. Die
                          Punktevergabe erfolgt nach einem eigenen
                          Punktevergabeschema und ist nur für die
                          OÖBV-Doppel-RLT gültig, die Ranglisten sind auf der
                          Homepage des OÖBV einzusehen.
                        </Paragraph>
                      </Space>
                    </Space>
                  </React.Fragment>
                )}

              {this.props.compoundContent.compound === 'sbv' &&
                this.props.compoundContent.restrictions.length <= 0 && (
                  <React.Fragment>
                    <Space bottom={20}>
                      <Topic>§ 01 ALLGEMEINES</Topic>
                      <Paragraph>
                        a. Bei dem Volksbank Salzburg Badmintoncup sollen die
                        Spieler/innen Spaß am Spiel haben. Es wird daher
                        versucht die Beschränkungen möglichst gering zu halten.
                        Ein Buffet sollte bei allen Turnieren vorhanden sein.
                      </Paragraph>
                    </Space>
                    <Space bottom={20}>
                      <Topic>Spielbetrieb</Topic>
                      <Paragraph>
                        a. Es sind 4 Cup-Turniere pro Saison durchzuführen.
                      </Paragraph>
                      <Paragraph>
                        b. Bei allen Turnieren wird der Doppel- bzw. Mixedbewerb
                        ausgetragen. Die Einzelbewerbe werden nach dem Doppel-
                        bzw. Mixedbewerb und nur bei entsprechender Hallengröße
                        ausgetragen.
                      </Paragraph>
                    </Space>
                    <Space bottom={20}>
                      <Topic>Spielregeln</Topic>
                      <Paragraph>
                        a. Alle Spiele sind nach den Regeln der Internationalen
                        Badminton Federation durchzuführen.
                      </Paragraph>
                    </Space>
                    <Space bottom={20}>
                      <Topic>Zählweise</Topic>
                      <Paragraph>
                        a. Grundsätzlich werden alle Spiele auf zwei gewonnen
                        Sätze bis 21 Punkte gespielt (mit Verlängerung und
                        Spielende bei 30 Punkten). Seitenwechsel in einen
                        dritten Entscheidungssatz bei 11 Punkten.
                      </Paragraph>
                      <Paragraph>
                        b. Beim Seitenwechsel ist eine Pause von 120 Sekunden
                        erlaubt.
                      </Paragraph>
                    </Space>
                    <Space bottom={20}>
                      <Topic>Turnierbälle</Topic>
                      <Paragraph>
                        a. Es kann mit zugelassenen Federbällen (Kielbälle) des
                        SBV bzw. ÖBV (Zulassungsklasse A und B) gespielt werden.
                      </Paragraph>
                      <Paragraph>
                        b. Nach Einigung beider Spieler kann auch mit
                        Plastikbällen gespielt werden.
                      </Paragraph>
                      <Paragraph>
                        c. Beide Spieler(innen) stellen vor dem Spiel gleich
                        viele Bälle. Sind diese Bälle verbraucht, so ist dies zu
                        wiederholen. Ist die Anzahl der angespielten Bälle nach
                        dem Spielende ungerade, so ist der letzte Ball einem der
                        beiden Spieler(innen) zuzulosen.
                      </Paragraph>
                    </Space>
                    <Space bottom={20}>
                      <Topic>§ 02 SPIELERLAUBNIS</Topic>
                      <Paragraph>
                        Grundsätzlich darf jeder an den Turnieren teilnehmen.
                      </Paragraph>
                    </Space>
                  </React.Fragment>
                )}
            </Card>
          </Space>
        </Flex>
      </Layout>
    );
  }
}

export default withRouter(
  connect(state => {
    const nextTournament = dataReducer.selectors.getNextTournament(state.data);
    const getCompoundContent = dataReducer.selectors.getCompoundContent(
      state.data
    );

    return {
      autoCompletion: dataReducer.selectors.getAutocompletion(
        nextTournament.valid,
        state.data.players
      ),
      compoundContent: getCompoundContent(nextTournament.compound),
      nextTournament,
      teamCount: dataReducer.selectors.getCount(nextTournament.id, state.data),
      teams: dataReducer.selectors.getTeams(nextTournament.id, state.data),
      user: userReducer.selectors.get(),
    };
  })(SubscribeContainer)
);
