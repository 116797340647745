import styled, { css } from 'styled-components'
import { mono3 } from '../../constants/colors'

export const Card = styled.div`
  border: 1px solid ${mono3};
  ${props => props.borderColor && `border-left: 1px solid ${props.borderColor};`};
  background-color: ${props => props.background || '#fff'};
  padding: 24px;
  border-radius: 4px;
`

export const CardImage = styled.div`
  height: 100%;
  padding-top: 50%;
  background-image: url('${props => props.src}');
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto;
  background-color: #fff;
  margin-left: -24px;
  margin-right: -24px;
  ${props => {
    if (props.sticky === 'bottom') {
      return css`
        ${props => props.spacing && 'margin-top: 24px;'};
        margin-bottom: -24px;
      `
    }
    return css`
      margin-top: -24px;
      ${props => props.spacing && 'margin-bottom: 24px;'};
    `
  }};

  ${props => props.cover && 'background-size: cover;'};
`
