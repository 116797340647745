import * as types from './types'

export function set(payload) {
  localStorage.setItem('@TaPlayer', JSON.stringify(payload))

  return {
    type: types.SET,
    payload,
  }
}

export function remove() {
  localStorage.removeItem('@TaPlayer')

  return {
    type: types.REMOVE,
  }
}
