import * as firebase from 'firebase'
import React, { Component } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import ScrollTop from './scroll-top'

// Containers
import ManageContainer from '../components/manage/manage-container'
import ImprintPage from '../components/pages/imprint-page'
import SubscribeContainer from '../components/subscribe/subscribe-container'
import TeamsContainer from '../components/teams/teams-container'
import TournamentsContainer from '../components/tournaments/tournaments-container'

class Router extends Component {
  render() {
    return (
      <ScrollTop>
        <Switch>
          <Route exact path="/impressum" component={ImprintPage} />
          <Route exact path="/datenschutz" component={ImprintPage} />

          <Route exact path="/" component={TournamentsContainer} />

          <Route exact path="/teams" component={TeamsContainer} />
          <Route exact path="/anmelden" component={SubscribeContainer} />
          <Route exact path="/manage" component={ManageContainer} />

          <Route
            path="/logout"
            render={props => {
              firebase.auth().signOut()
              return (
                <Redirect
                  to={{
                    pathname: '/',
                    state: { from: props.location },
                  }}
                />
              )
            }}
          />


          <Route render={() => <Redirect to={{ pathname: '/' }} />} />
        </Switch>
      </ScrollTop>
    )
  }
}

export default Router
