import PropTypes from 'prop-types'
import * as R from 'ramda'
import React from 'react'
import styled from 'styled-components'

// Components
import Flex from '../common/flex'

const Logo = styled.img`
  height: 60px;
  width: auto;
`

const Link = styled.a`
  border: 0;
  text-decoration: none;
  padding: 10px;
`

function Clubs (props) {
  const mapIndexed = R.addIndex(R.map);

  return (
    <Flex align="center" justify="space-around" wrap="wrap">
      {mapIndexed(
        (club, index) => (
          <Link key={index} href={club.website} target="_blank">
            <Logo src={club.path} alt={club.operator} />
          </Link>
        ),
        props.clubs
      )}
    </Flex>
  )
}

Clubs.propTypes = {
  clubs: PropTypes.array.isRequired,
}

export default Clubs
