import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

// Constants
import { content } from '../../constants/layout'

// Common
import Flex from '../common/flex'
import Space from '../common/space'

// Components
import Sidebar from './sidebar'

const Container = styled.div`
  ${content};
  min-height: calc(100vh - 353px);

  @media screen and (min-width: 961px) {
    display: flex;
  }

  @media screen and (max-width: 500px) {
    padding-left: 10px;
    padding-right: 10px;
  }
`

const Wrapper = styled(Flex)`
  @media screen and (min-width: 1020px) {
    flex-direction: row;
  }
`

const SidebarWrapper = styled.div`
  width: 100%;

  @media screen and (min-width: 1020px) {
    width: 320px;
    margin-left: 30px;
  }
`

function Layout(props) {
  return (
    <div>
      <Space bottom={30} />

      <Container>
        <Wrapper column wide>
          {props.children}

          {(props.tournament && props.tournament.id) &&
            <SidebarWrapper>
              <Space bottom={30}>
                <Sidebar
                  count={props.count}
                  tid={props.tournament && props.tournament.id}
                />
              </Space>
            </SidebarWrapper>
          }
        </Wrapper>

      </Container>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  count: PropTypes.number,
  tournament: PropTypes.object,
}

export default Layout
