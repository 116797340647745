import * as R from 'ramda'
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

// Constants
import { mono3 } from '../../constants/colors'

import Icon from './icon'

const Wrapper = styled.div`
  position: relative;
`

const IconWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 10px;
  height: 100%;
  display: flex;
  align-items: center;
  pointer-events: none;
`

const Field = styled.select`
  width: 100%;
  height: 100%;
  padding-left: ${props => (props.spaceLeft ? 30 : 10)}px;
  padding-right: 10px;
  font-size: 1em;
  background-color: #fff;
  background-image: none;
  border: 1px solid ${mono3};
  height: 50px;
  font-family: 'Open Sans', sans-serif;
  -webkit-appearance: none;
`

function Select (props) {
  return (
    <Wrapper>
      <Field spaceLeft={props.spaceLeft} onChange={props.onChange}>
        {R.map(
          option => (
            <option key={option.key} value={option.key}>
              {option.label}
            </option>
          ),
          props.options
        )}
      </Field>

      <IconWrapper>
        <Icon name="arrow_down" size={16} />
      </IconWrapper>
    </Wrapper>
  )
}

Select.defaultPropTypes = {
  options: [],
}

Select.propTypes = {
  spaceLeft: PropTypes.bool,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default Select
