import * as R from 'ramda'
import React, { Component } from 'react'
import { Redirect } from 'react-router'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'

// Redux
import { connect } from 'react-redux'
import dataReducer from '../../reducers/data/'
import popupReducer from '../../reducers/popup'

// Constants
import { danger, mono0, mono1, mono3, mono4 } from '../../constants/colors'

// Helpers
import { getCompetitionName, getPlayerCount } from '../../helpers/competitions'

// Common
import { Card } from '../common/card'
import Flex from '../common/flex'
import Select from '../common/select'
import Space from '../common/space'

// Components
import Layout from '../layout/layout'
import Player from '../shared/player'

const Headline = styled.div`
  display: flex;
  align-items: center;
  font-size: 20px;
  border-bottom: 1px solid ${mono3};
  padding-bottom: 20px;
  margin-left: 5px;
`

const SubscriptionCount = styled.span`
  border-radius: 4px;
  font-size: 14px;
  line-height: 14px;
  margin-left: 10px;
  padding: 4px 8px;
  color: ${mono4};
  background-color: ${mono1};
  border: 1px solid ${mono3};
`

const Team = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px 5px;
  border-bottom: 1px solid ${mono1};

  &:last-child {
    border-bottom: 0;
  }

  &:hover {
    background-color: ${mono0};
  }
`

const NoTeams = styled.div`
  color: ${mono4};
  font-style: italic;
`

const Delete = styled.span`
  color: ${danger};
  font-size: 0.8em;
  cursor: pointer;
`

const Information = styled(Delete)`
  color: #2a91fb;
  margin-left: 10px;
`

class TeamsContainer extends Component {
  state = {
    activeIndex: 0,
  }

  getFilteredCompetitions () {
    const competition = R.path([this.state.activeIndex], R.concat(['all'], this.props.competitions))
    switch (competition) {
      case 'all':
        return this.props.competitions
      default:
        return [competition]
    }
  }

  deleteTeam (_key, _competition) {
    const team = R.path([_competition, _key], this.props.teams)
    const playerCount = getPlayerCount(_competition)

    let message = ''
    if (playerCount > 1) {
      message = `Paarung ${team.p0.firstname} und ${team.p1.firstname} wirklich löschen?`
    } else {
      message = `${team.p0.firstname} wirklich löschen?`
    }

    if (window.confirm(message) === true) {
      this.props.dispatch(
        dataReducer.actions.remove({
          tid: this.props.nextTournament.id,
          key: _key,
          competition: _competition,
        })
      )
    }
  }

  handleInformation (_meta) {
    const d = new Date(_meta.createdAt)

    this.props.dispatch(
      popupReducer.actions.showInfo(
        'Information',
        `Anmeldung via "${
          _meta.platform
        }" am ${d.toLocaleDateString()} um ${d.toLocaleTimeString()} durch ${
          _meta.user.firstname
        } ${_meta.user.lastname} (${_meta.user.id}). `
      )
    )
  }

  // get structure for competition selector
  getCompetitionOptions () {
    return R.concat(
      [{ key: 0, label: 'Alle Nennungen' }],
      R.split(',', this.props.nextTournament.competition).map((competition, index) => ({
        key: index + 1,
        label: getCompetitionName(competition),
      }))
    )
  }

  render () {
    if (!this.props.nextTournament.id) {
      return <Redirect to="/" />
    }

    return (
      <Layout count={this.props.teamCount} tournament={this.props.nextTournament}>
        <Flex column wide>
          <Space bottom={30}>
            <Select
              spaceLeft
              options={this.getCompetitionOptions()}
              onChange={e => {
                this.setState({ activeIndex: e.target.value })
              }}
            />
          </Space>

          <Flex column wide>
            {R.map(competition => {
              const teamKeys = R.keys(R.path([competition], this.props.teams))

              return (
                <Space bottom={30} key={competition}>
                  <Card>
                    <Headline>
                      {getCompetitionName(competition)}
                      {teamKeys.length > 0 && (
                        <SubscriptionCount>{teamKeys.length}</SubscriptionCount>
                      )}
                    </Headline>

                    {teamKeys.length === 0 && (
                      <Space top={20}>
                        <NoTeams>Aktuell sind keine Nennungen für diesen Bewerb vorhanden</NoTeams>
                      </Space>
                    )}

                    {R.map(key => {
                      const team = R.path([competition, key], this.props.teams)

                      return (
                        <Team key={key}>
                          <Player
                            club={team.p0.club}
                            id={team.p0.id}
                            name={`${team.p0.firstname} ${team.p0.lastname}`}
                          />
                          {R.length(R.keys(team.p1)) > 0 && (
                            <Player
                              club={team.p1.club}
                              id={team.p1.id}
                              name={`${team.p1.firstname} ${team.p1.lastname}`}
                            />
                          )}

                          {this.props.auth && (
                            <Flex>
                              <Delete onClick={() => this.deleteTeam(key, competition)}>
                                Löschen
                              </Delete>

                              <Information onClick={() => this.handleInformation(team.meta)}>
                                Information
                              </Information>
                            </Flex>
                          )}
                        </Team>
                      )
                    }, R.reverse(teamKeys))}
                  </Card>
                </Space>
              )
            }, this.getFilteredCompetitions())}
          </Flex>
        </Flex>
      </Layout>
    )
  }
}

export default withRouter(
  connect(state => {
    const nextTournament = dataReducer.selectors.getNextTournament(state.data)

    return {
      auth: state.auth,
      competitions: nextTournament.id && nextTournament.competition.split(','),
      nextTournament,
      teamCount: dataReducer.selectors.getCount(nextTournament.id, state.data),
      teams: dataReducer.selectors.getTeams(nextTournament.id, state.data),
    }
  })(TeamsContainer)
)
