import * as firebase from 'firebase';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

// Redux
import { connect } from 'react-redux';
import authReducer from '../reducers/auth/';
import dataReducer from '../reducers/data';

// Common
import Flex from './common/flex';
import Spinner from './common/spinner';

// Components
import Cookies from '../components/cookies';
import PopupContainer from './popup/popup-container';
import RootContainer from './root-container';

// Router
import RouterAnonym from '../router/router-anonym';

export const PageWrapper = styled.div`
  min-height: 100vh;
`;

export const Centered = styled(Flex)`
  width: 100%;
  min-height: 100vh;
`;

const DB_REFS = ['tournaments', 'content', 'notifications', 'players', 'teams'];
const DB_REFS_ADMIN = ['push', 'whoisusing'];

class Preloader extends Component {
  state = {
    loading: true,
    isAuth: false,
  };

  componentDidMount() {
    firebase.auth().onAuthStateChanged(user => {
      if (!user) {
        this.setState({ loading: false, isAuth: false });
        return;
      }

      this.setState({ loading: false, isAuth: true });

      DB_REFS.map(ref => {
        this.props.dispatch(dataReducer.actions.get(ref));
        return true;
      });

      if (!user.isAnonymous) {
        DB_REFS_ADMIN.map(ref => {
          this.props.dispatch(dataReducer.actions.get(ref));
          return true;
        });

        this.props.dispatch(authReducer.actions.set(Boolean(user)));
      }
    });
  }

  renderComponent(_component) {
    return (
      <PageWrapper>
        <Cookies />
        <Centered wide align="center" justify="center">
          {_component}
        </Centered>
        <PopupContainer />
      </PageWrapper>
    );
  }

  isDataReady() {
    if (!this.props.data) {
      return;
    }

    const requiredRefs = ['tournaments', 'content', 'players'];
    const ref = DB_REFS.filter(
      ref => requiredRefs.indexOf(ref) >= 0 && this.props.data[ref]
    );

    return ref.length === requiredRefs.length;
  }

  render() {
    if (!this.state.loading) {
      if (!this.state.isAuth) {
        return this.renderComponent(<RouterAnonym />);
      }

      if (this.isDataReady()) {
        return this.renderComponent(<RootContainer />);
      }
    }

    return (
      <Centered wide align="center" justify="center">
        <Spinner />
      </Centered>
    );
  }
}

export default withRouter(
  connect(state => ({
    data: state.data,
  }))(Preloader)
);
