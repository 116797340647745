import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import { mono0, mono2, mono3 } from '../../constants/colors'
import Flex from '../common/flex'

const Link = styled.div`
  display: block;
  padding: 20px;
  color: ${props => props.active ? mono0 : mono2};
  text-decoration: none;
  cursor: pointer;
  
  &:hover {
    color: #fff;
  }

  @media screen and (min-width: 769px) {
    &:last-child {
      padding-right: 0;
    }
  }

  ${props => props.mobile && css`
      width: 100%;
      text-align: center;
  `};

  @media screen and (max-width: 768px) {
    ${props => !props.active && `color: ${mono3};`};
    font-size: 20px;
    padding-top: 30px;
    padding-bottom: 30px;
  }
`

function Navigation(props) {
  return (
    <Flex align="center" column={props.mobile}>
      <Link
        active={props.pathname === '/'}
        mobile={props.mobile}
        onClick={() => props.onClick('/')}>
        Turniere
      </Link>
      {!props.isSeasonEnd &&
        <React.Fragment>
          <Link
            active={props.pathname === '/anmelden'}
            mobile={props.mobile}
            onClick={() => props.onClick('/anmelden')}>
            Jetzt Anmelden
          </Link>
          <Link
            active={props.pathname === '/teams'}
            mobile={props.mobile}
            onClick={() => props.onClick('/teams')}>
            Nennungen
          </Link>
        </React.Fragment>
      }
    </Flex>
  )
}

Navigation.propTypes = {
  isSeasonEnd: PropTypes.any,
  mobile: PropTypes.bool,
  pathname: PropTypes.string,
  onClick: PropTypes.func.isRequired,
}

export default Navigation
