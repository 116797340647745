import PropTypes from 'prop-types'
import styled from 'styled-components'

const Space = styled.div`
  padding-top: ${props => props.top || props.y || props.all}px;
  padding-bottom: ${props => props.bottom || props.y || props.all}px;
  padding-right: ${props => props.right || props.x || props.all}px;
  padding-left: ${props => props.left || props.x || props.all}px;
`

Space.defaultProps = { all: 0 }

Space.propTypes = {
  all: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  bottom: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  left: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  right: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  top: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  x: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  y: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

export default Space
