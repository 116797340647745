import * as firebase from 'firebase';
import * as R from 'ramda';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

// Redux
import { connect } from 'react-redux';
import popupReducer from '../../reducers/popup';
import userReducer from '../../reducers/user';

// Common
import Button from '../common/button';
import { Card } from '../common/card';
import Flex from '../common/flex';
import Input from '../common/input';
import Space from '../common/space';
import Stores from '../common/stores';

// Components
import Logo from './logo';

import { mono2 } from '../../constants/colors';

export const Container = styled(Flex)`
  padding-left: 10px;
  padding-right: 10px;
`;

export const Wrapper = styled.div`
  max-width: 380px;
  width: 100%;
`;

export const Small = styled.div`
  color: ${mono2};
  font-size: 12px;
  text-align: center;
`;

const Restrictions = styled(Small)`
  text-align: justify;
`;

const Link = styled.a`
  color: inherit;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const Seperator = styled.div`
  margin-left: 10px;
  margin-right: 10px;
`;

class LoginContainer extends Component {
  state = {
    form: {
      lastname: '',
      id: '',
    },
  };

  handleChangeInput = (_key, _value) => {
    this.setState({
      form: Object.assign({}, this.state.form, { [_key]: _value }),
    });
  };

  handleSubmit = () => {
    const id = this.state.form.id;
    const lastname = this.state.form.lastname.toLowerCase().trim();

    firebase
      .database()
      .ref(`/verification/${id}/${lastname}`)
      .once('value', snapshot => {
        const localUser = snapshot.val();

        if (!localUser || !localUser.id) {
          this.props.dispatch(
            popupReducer.actions.showError(
              'Upps!',
              'Deine Daten stimmen leider nicht mit der OÖBV Datenbank überein! Bitte gleiche deinen Namen und deine Spieler ID mit deiner OÖBV Anmeldung ab.'
            )
          );
          return;
        }

        this.performLogin(localUser);
      });
  };

  performLogin(localUser) {
    firebase.auth().onAuthStateChanged(user => {
      if (user && user.isAnonymous) {
        this.initLocalUser(localUser);
      }
    });

    firebase
      .auth()
      .signInAnonymously()
      .catch(error => {
        if (error) {
          this.props.dispatch(
            popupReducer.actions.showError(
              'Upps!',
              'Beim Herstellen der Datenbankverbindung ist ein Problem aufgetreten.'
            )
          );
        }
      });
  }

  initLocalUser(localUser) {
    // store user at localStorage
    // when user reducer is updated preloader.js will handle redirect
    this.props.dispatch(userReducer.actions.set(localUser));

    const meta = {
      createdAt: String(new Date()),
      platform: 'web',
      user: localUser,
    };

    // save players which uses the web version
    firebase.database().ref(`/whoisusing/web/${localUser.id}`).set(meta);

    window.scroll(0, 0);
  }

  isSubmitDisabled() {
    const validFormValues = R.filter(item => item, R.values(this.state.form));
    return R.not(
      R.equals(R.length(validFormValues), R.length(R.keys(this.state.form)))
    );
  }

  render() {
    return (
      <Container justify="center" align="center" column>
        <Wrapper>
          <Card>
            <Space bottom={30}>
              <Logo />
            </Space>

            <Space bottom={20}>
              <Input
                name="lastname"
                placeholder="Nachname"
                value={this.state.form.lastname}
                onChange={this.handleChangeInput}
              />
            </Space>

            <Space bottom={20}>
              <Input
                name="id"
                placeholder="ÖBV Nummer"
                type="number"
                value={this.state.form.id}
                onChange={this.handleChangeInput}
              />
            </Space>

            <Space bottom={20}>
              <Button
                disabled={this.isSubmitDisabled()}
                streched
                onClick={this.handleSubmit}
              >
                Verifizieren
              </Button>
            </Space>

            <Restrictions>
              Mit der Verifizierung stimmst du den Datenschutzrichtlinien der TA
              (turnieranmeldung.at) zu.
            </Restrictions>
          </Card>

          <Space top={20} bottom={20}>
            <Stores />
          </Space>

          <Space bottom={20}>
            <Small>
              <Space bottom={10}>
                &copy; 2013-{new Date().getFullYear()}&nbsp;
                <Link
                  href="https://ask-nettingsdorf-badminton.at"
                  target="_blank"
                >
                  ASK Nettingsdorf Badminton/Racketlon
                </Link>
              </Space>

              <Flex justify="center">
                <Link onClick={() => this.props.history.push('impressum')}>
                  Impressum
                </Link>
                <Seperator>|</Seperator>
                <Link onClick={() => this.props.history.push('datenschutz')}>
                  Datenschutz
                </Link>
              </Flex>
            </Small>
          </Space>
        </Wrapper>
      </Container>
    );
  }
}

export default withRouter(
  connect(state => ({
    data: state.data,
  }))(LoginContainer)
);
