import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

// Constants
import { mono2 } from '../../constants/colors'

const Number = styled.div`
  color: ${mono2};
  font-size: 12px;
`

const Headline = styled.div`
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: 400;
`

const Description = styled.p`
  text-align: justify;
`

function TournamentHeader (props) {
  return (
    <div>
      <div>{props.date}</div>
      <Number>
        {props.id}. {props.compoundContent.type}
      </Number>

      <Headline>{props.operator}</Headline>
      <Description>
        {props.compoundContent.article} {props.id}. {props.compoundContent.type} wird am{' '}
        {props.date}
        {props.venue && ` in der ${props.venue}`} ausgerichtet. Gespielt wird auf {props.fields}{' '}
        Feldern, Nennschluss ist {props.closing} um 23:59 Uhr.
      </Description>
    </div>
  )
}

TournamentHeader.propTypes = {
  closing: PropTypes.string.isRequired,
  compoundContent: PropTypes.object.isRequired,
  date: PropTypes.string.isRequired,
  fields: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  operator: PropTypes.string.isRequired,
  venue: PropTypes.string.isRequired,
}

export default TournamentHeader
