import * as R from 'ramda'
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

// Constants
import { mono1, mono3 } from '../../constants/colors'

// Components
import InputField from '../common/input'
import Player from '../shared/player'

const Container = styled.div`
  position: relative;
  margin-bottom: 20px;
`

const ResultWrapper = styled.div`
  border: 1px solid ${mono3};
  border-top: 0;
`

const Result = styled.div`
  padding: 15px 10px;
  cursor: pointer;
  
  &:not(:last-child) {
    border-bottom: 1px solid #e2e2e2;
  }

  &:hover {
    background-color: ${mono1};
  }
`

const Autocomplete = styled.div`
  position: absolute;
  top: 50px;
  left: 0;
  z-index: 10;
  background-color: #fff;
  width: 100%;
`

function SelectPlayer (props) {
  return (
    <Container>
      <InputField
        type="text"
        placeholder="Spielernamen suchen"
        name={props.name}
        value={props.value}
        disabled={props.disabled}
        onChange={props.onChange}
        onClear={props.onClear}
      />

      {props.autoComplete &&
        <Autocomplete>
          <ResultWrapper>
            {R.equals(R.length(props.autoComplete), 0) &&
              <Result>
                Kein Spieler gefunden
              </Result>
            }

            {R.gt(R.length(props.autoComplete), 0) && R.map(player => (
              <Result
                key={player.id}
                onClick={() => props.onSelect(player)}>
                <Player
                  club={player.club}
                  id={player.id}
                  name={`${player.firstname} ${player.lastname}`}
                />
              </Result>
            ), props.autoComplete)}
          </ResultWrapper>
        </Autocomplete>
      }
    </Container>
  )
}

SelectPlayer.propTypes = {
  autoComplete: PropTypes.array,
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  value: PropTypes.string,
}

export default SelectPlayer
