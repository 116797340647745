import React from 'react'
import styled from 'styled-components'

import { base } from '../../constants/colors'
import Flex from '../common/flex'

const LogoImage = styled.img`
  height: 40px;
  margin-right: 10px;
`

const Title = styled.div`
  color: ${base};
  font-size: 26px;
`

function Logo () {
  return (
    <Flex align="center" justify="center">
      <LogoImage src="assets/logo_dark.png" />
      <Title>Turnieranmeldung</Title>
    </Flex>
  )
}

export default Logo
