import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

// Constants
import { mono2 } from '../../constants/colors'

// Common
import Flex from '../common/flex'

const Name = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: 768px) {
    width: 100%;
    justify-content: space-between;
  }
`

const Id = styled.div`
  font-size: 0.8em;
  color: ${mono2};
  margin-left: 10px;
`

const Club = styled.div`
  color: ${mono2};
  font-size: 0.8em;

  @media screen and (max-width: 768px) {
    display: none;
  }
`

function Player (props) {
  return (
    <Flex justify="space-between" align="center">
      <Name>
        {props.name}
        <Id>({props.id})</Id>
      </Name>
      <Club>{props.club}</Club>
    </Flex>
  )
}

Player.propTypes = {
  club: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
}

export default Player
