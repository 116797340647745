import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'

import ScrollTop from './scroll-top'

// Containers
import ImprintPage from '../components/pages/imprint-page'
import LoginContainer from '../components/login/login-container'

class RouterAnonym extends Component {
  render() {
    return (
      <ScrollTop>
        <Switch>
          <Route exact path="/" component={LoginContainer} />
          <Route exact path="/impressum" component={ImprintPage} />
          <Route exact path="/datenschutz" component={ImprintPage} />
        </Switch>
      </ScrollTop>
    )
  }
}

export default RouterAnonym
