export const white = '#fff';

export const mono0 = '#f9f9f9';
export const mono1 = '#f5f5f5';
export const mono3 = '#e8e8e8';
export const mono2 = '#a3a3a3';
export const mono4 = '#999';
export const mono5 = '#363d44';

export const highlight = '#c55853';
export const danger = '#FB532A';
export const success = '#18cf65';

export const base = '#262626';
