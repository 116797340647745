import { combineReducers } from 'redux'

import auth from './auth/'
import data from './data/'
import user from './user/'
import popup from './popup/'

const rootReducer = combineReducers({
  auth: auth.reducer,
  data: data.reducer,
  user: user.reducer,
  popup: popup.reducer,
})

export default rootReducer
