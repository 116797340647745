export function getCompetitionName(_key) {
  switch (_key) {
    case 'de':
      return 'Damen Einzel';
    case 'de-a':
      return 'Damen Einzel Amateure';
    case 'de-b':
      return 'Damen Einzel Beginner';
    case 'dd':
      return 'Damen Doppel';
    case 'dd-a':
      return 'Damen Doppel Amateure';
    case 'dd-b':
      return 'Damen Doppel Beginner';
    case 'ddx':
      return 'Damen Doppel X';

    case 'he':
      return 'Herren Einzel';
    case 'he-a':
      return 'Herren Einzel Amateure';
    case 'he-b':
      return 'Herren Einzel Beginner';
    case 'hd':
      return 'Herren Doppel';
    case 'hd-a':
      return 'Herren Doppel Amateure';
    case 'hd-b':
      return 'Herren Doppel Beginner';
    case 'hdx':
      return 'Herren Doppel X';

    case 'md':
      return 'Mixed Doppel';
    case 'md-a':
      return 'Mixed Doppel Amateure';
    case 'md-b':
      return 'Mixed Doppel Beginner';

    case 'xd':
      return 'Unisex Doppel';

    default:
      return '?';
  }
}

export function getPlayerCount(_key) {
  switch (_key) {
    case 'de':
    case 'de-a':
    case 'de-b':
    case 'he':
    case 'he-a':
    case 'he-b':
    case 'ddx':
    case 'hdx':
      return 1;
    default:
      return 2;
  }
}

export function getPlayerSex(_key) {
  switch (_key) {
    case 'de':
    case 'de-a':
    case 'de-b':
    case 'dd':
    case 'dd-a':
    case 'dd-b':
    case 'ddx':
      return 'w';
    case 'he':
    case 'he-a':
    case 'he-b':
    case 'hd':
    case 'hd-a':
    case 'hd-b':
    case 'hdx':
      return 'm';
    default:
      return 'wm';
  }
}
