import React, { Component } from 'react';
import styled from 'styled-components';

// Constants
import { mono1, mono4 } from '../constants/colors';

// Common
import Button from './common/button';

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: ${mono1};
  border-bottom: 1px solid ${mono4};
  z-index: 10;
`;

const Content = styled.div`
  max-width: 650px;
  margin-left: auto;
  margin-right: auto;
  padding: 10px 20px;
`;

const Headline = styled.div`
  font-weight: 500;
  margin-bottom: 10px;
`;

const Description = styled.div`
  text-align: justify;
  padding-bottom: 10px;
`;

class Cookies extends Component {
  constructor(props) {
    super(props);

    this.handleAccept = this.handleAccept.bind(this);

    this.state = {
      accepted: null,
    };
  }

  componentDidMount() {
    const accepted = Boolean(localStorage.getItem('@taCookieAccepted'));
    this.setState({ accepted });
  }

  handleAccept() {
    localStorage.setItem('@taCookieAccepted', true);
    this.setState({ accepted: true });
  }

  render() {
    if (this.state.accepted === null || this.state.accepted) {
      return null;
    }

    return (
      <Wrapper>
        <Content>
          <Headline>Wir verwenden Cookies</Headline>
          <Description>
            Unsere Website verwendet Cookies, die uns helfen, unsere Website zu
            verbessern, den bestmöglichen Service zu bieten und ein optimales
            Kundenerlebnis zu ermöglichen. Weitere Informationen finden Sie in
            unserer Datenschutzerklärung.
          </Description>
          <Button small onClick={() => this.handleAccept()}>
            Akzeptieren
          </Button>
        </Content>
      </Wrapper>
    );
  }
}

export default Cookies;
