import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

// Constants
import { highlight } from '../../constants/colors'

// Components
import Flex from '../common/flex'
import { Card } from '../common/card'

const Headline = styled.div`
  color: #fff;
`

const Counter = styled.div`
  color: #fff;
  line-height: 1em;
  font-size: 60px;
  font-weight: 400;
  margin-top: 10px;
`

function SubscriptionCard (props) {
  return (
    <Card background={highlight}>
      <Flex column align="center">
        <Headline>Nennungen</Headline>
        <Counter>{props.count}</Counter>
      </Flex>
    </Card>
  )
}

SubscriptionCard.propTypes = {
  count: PropTypes.number.isRequired,
}

export default SubscriptionCard
