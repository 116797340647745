export function get() {
  const user = localStorage.getItem('@TaPlayer') || {}

  if (typeof user === 'string') {
    return JSON.parse(user)
  }
  return user
}

export function isVerified() {
  const user = get()

  if (user && user.id) {
    return true
  }
  return false
}
