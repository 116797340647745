import * as R from 'ramda'
import { stringToTimestamp, getTimestampEndOfDay } from '../../helpers/date'
import { shuffleArray } from '../../helpers/shuffle'

export function getClubs(_state) {
  // unique shuffled club list
  const clubs = R.uniq(
    shuffleArray(
      R.map(
        item => ({
          operator: item.operator,
          path: item.path,
          website: item.website,
        }),
        _state.tournaments
      )
    )
  )
  // return max 4 randomized clubs
  return R.slice(0, 4, clubs)
}

export function getTournaments(_state) {
  return R.concat(getUpcomingTournaments(_state), getPreviousTournaments(_state))
}

export function getUpcomingTournaments(_state) {
  return R.filter(
    item => stringToTimestamp(item.date) >= getTimestampEndOfDay(),
    _state.tournaments
  )
}

export function getPreviousTournaments(_state) {
  return R.filter(item => {
    if (stringToTimestamp(item.date) < getTimestampEndOfDay()) {
      item._previous = true
      return item
    }
    return null
  }, _state.tournaments)
}

export function getNextTournament(_state) {
  const upcoming = getUpcomingTournaments(_state)
  return R.length(upcoming) ? R.head(upcoming) : {}
}

export function getCount(_tid, _state) {
  if (!_state.teams || !_state.teams[_tid]) {
    return 0
  }

  const counts = R.map(competition => {
    const teams = R.path([_tid, competition], _state.teams)
    return R.length(R.keys(teams))
  }, R.filter(team => team !== 'ps', R.keys(R.prop(_tid, _state.teams))))

  return R.sum(counts)
}

export function getTeams(_tid, _state) {
  return R.or(R.path([_tid], _state.teams), {})
}

export function getAutocompletion(_validplayers, _state) {
  if (!_state) {
    return () => null
  }
  return (_search, _sex) => {
    if (!_search) {
      return null
    }
    const result = R.filter(
      item =>
        R.and(
          R.gte(R.indexOf(_search, item.fullname), 0),
          R.and(
            R.gte(R.indexOf(item.sex.toLowerCase(), _sex), 0),
            R.length(R.intersection(R.split(',', item.valid), R.split(',', _validplayers)))
          )
        ),
      _state
    )
    return R.slice(0, 5, result)
  }
}

export function getNotifications(_state) {
  return R.propOr({}, 'notifications', _state)
}

export function getPushReceivers(_state) {
  return R.map(receiver => receiver.token, R.values(_state.push))
}

export function getCompoundContent(_state) {
  return _compound => R.head(R.filter(item => item.compound === _compound, _state.content))
}
