import * as R from 'ramda'

export function stringToTDate (_string) {
  const reverse = _string.split('.').reverse().join('/')
  return new Date(reverse)
}

export function stringToTimestamp (_string) {
  return stringToTDate(_string).getTime()
}

export function toLocalDateString (_string) {
  const timestamp = stringToTDate(_string)
  const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }

  return timestamp.toLocaleDateString('de-DE', options)
}

export function getTimestampEndOfDay (_timestamp) {
  const d = _timestamp ? new Date(_timestamp) : new Date()
  d.setHours(23)
  d.setMinutes(59)
  d.setSeconds(59)
  return d
}

// TODO maybe delete
export function isPreviousTournament (_string) {
  return stringToTimestamp(_string) < getTimestampEndOfDay()
}

export function getPreviousTournaments (_tournaments) {
  return R.filter(item => (
    stringToTimestamp(item.date) < getTimestampEndOfDay()
  ), _tournaments)
}
